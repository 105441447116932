import { useEffect } from "react";
import { Table } from "react-bootstrap"

export const TermsAndConditionEN = () => {
    useEffect(() => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }, []);
    return (
        <>
            <section className="termconditons pb-5 mb-3">
                <div className="container">
                    <h2 className="term-heading text-center mt-lg-4 mt-2">Terms and Conditions - LYBER</h2>
                    <div className="termcondition-logo">
                        <div className="inner-condition">
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">1.</span>Customer
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Client </li>
                                <li>
                                    Refers to any individual or legal entity with a Customer Account registered with Lyber.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">AMF </li>
                                <li>
                                    French Financial Markets Authority.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">ACPR </li>
                                <li>
                                    Prudential Control and Resolution Authority.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">CMF </li>
                                <li>
                                    Monetary and Financial Code.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Customer Account</li>
                                <li>
                                    Refers to the account enabling a User to use the Services provided by Lyber and to acquire an Internal Wallet.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">General Terms and Conditions</li>
                                <li>
                                    Refers to the present Terms and Conditions, which constitute a legally binding agreement between Users/Customers and Lyber, governing access and use by Users/Customers of the Platform and all associated Services.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Customer contract<br /> / agreement</li>
                                <li>
                                    Refers to the framework contract for payment services signed between Users and Lyber.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Digital assets</li>
                                <li>
                                    A digital asset is an asset made up of digital data, the ownership or right to use of which is part of the assets of a natural or legal person.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Crypto currency<br /> / crypto assets</li>
                                <li>
                                    Means any digital asset designed to function as a medium of exchange on a blockchain and accepted as a means of payment or exchange in all Services.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">FIAT currency</li>
                                <li>A currency whose value derives essentially from the fact that a government
                                    imposes its legal tender status on a given territory through a central bank.
                                    According to article L111-1 of the French Monetary and Financial Code,
                                    "France's currency is the euro". Since 2000, the euro has been the fiat
                                    currency in most European Union countries.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Working day</li>
                                <li>
                                    Refers to all days of the week, with the exception of the weekly rest day (usually Sunday) and public holidays not usually worked.
                                </li>
                            </ul>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Lyber</li>
                                <li>Refers to Lyber, a société par actions simplifiée (simplified joint stock
                                    company) with capital of 2809,52€, registered with the Paris Trade and
                                    Companies Registry under number 903 241 925, having its registered office
                                    at 15 rue des Halles, 75001 Paris.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Order</li>
                                <li>
                                    Refers to a customer's consent to the execution of a transaction.
                                </li>
                            </ul>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Partners</li>
                                <li>Refers to service providers assisting Lyber in fulfilling its LCB-FT obligations.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Parties</li>
                                <li>
                                    Refers to Lyber and the User or Customer.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Platform</li>
                                <li>
                                    Means the mobile application and the lyber.com website including all its sub-
                                    domains, and any platform on which the services referred to in the GTC are available.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">External Wallet</li>

                                <li>
                                    Means the User's personal non-custodial wallet containing the User's
                                    Cryptocurrencies, not held by Lyber but identified as available to receive
                                    deposits.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Internal Wallet</li>

                                <li>
                                    Refers to the custodial wallet containing the User's Cryptocurrencies, kept, held and
                                    managed by Lyber on behalf of the User, through the Custodian.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Preservation service<br />provider</li>
                                <li>
                                    Provider of a technical solution for preserving digital assets at Lyber.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Liquidity <br />provider</li>
                                <li>
                                    Provider of liquidity to Lyber.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">PSAN </li>
                                <li>
                                    Refers to the regulatory status of Digital Asset Service Provider.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">KYC</li>
                                <li>LYBER user identification and verification procedure</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">LCB-FT</li>
                                <li>Combating Money Laundering and Terrorist Financing</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Services</li>
                                <li>
                                    Refers to all services accessible on the Platform with a Customer Account,
                                    as defined in section 5.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Third Party</li>
                                <li>
                                    Refers to any person or entity other than Lyber or Users/Customers.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Transactions</li>
                                <li>Refers to the action of transferring or withdrawing funds to or from a customer
                                    account, an External Portfolio and/or a bank account.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Gaz fees / fees</li>
                                <li>
                                    Commission paid to miners or validators who validate a given transaction on
                                    the blockchain.
                                </li>
                            </ul>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">User</li>
                                <li>Refers to any individual or legal entity browsing the Platform without being a
                                    Customer.</li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="partof-names ms-lg-4">Transfer between <br />Friends</li>
                                <li>
                                    Refers to transfers between KYC-verified users.
                                </li>
                            </ul>



                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">2.</span>Acceptance of risks
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>1.</small>
                                </li>
                                <li>
                                    <b>Acceptance of T&Cs.</b> In accordance with applicable law, Lyber's PSAN registration can be
                                    verified at any time at <a href="https://www.amf-france.org.">www.amf-france.org.</a>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>2.</small>
                                </li>
                                <li>
                                    These GTC govern the opening of a Customer Account in the name of each User and apply,
                                    without restriction or reservation, to all use of the Services offered on the Platform. In order
                                    to use the Services, Users agree to read and accept, unconditionally and without
                                    reservation, the present GTC.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>3.</small>
                                </li>
                                <li>
                                    These GCS are accessible at all times on the Platform and shall prevail over any other version
                                    or any other contradictory document. Users are invited to read these GTC carefully before
                                    accepting them.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>4.</small>
                                </li>
                                <li>
                                    Each User acknowledges that, in his or her country, he or she has the capacity to contract
                                    and subscribe to the Platform and declares that he or she has read and accepted, without
                                    restriction or reservation, the General Terms and Conditions of Sale by checking the
                                    corresponding box when creating a Customer Account on the Platform.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>5.</small>
                                </li>
                                <li>
                                    LYBER provides payment services in the name and on behalf of Stripe. To this end, each User
                                    acknowledges and accepts these GTC.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>6.</small>
                                </li>
                                <li>
                                    <b>Modification of the GTC.</b> These GTC may be amended from time to time as described in
                                    section 12. The applicable version of the GCS is that in force on the Platform at the date of
                                    creation of the Customer Account.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>7.</small>
                                </li>
                                <li>
                                    <b>Risks associated with the Services.</b> Customers acknowledge that they have conducted
                                    personal research before subscribing to the Services. The Services provided by Lyber are
                                    related to digital assets and blockchain technology, which entails several consequences.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>8.</small>
                                </li>
                                <li>
                                    On the one hand, Users are aware of the economic risks associated with the Services. In this
                                    respect, they acknowledge that digital asset markets are decentralized, that the Services are
                                    not suitable for many investors and represent a high level of risk of loss of capital,
                                    particularly due to extreme market volatility.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>9.</small>
                                </li>
                                <li>
                                    Consequently, although the risk of loss does not exceed the amount invested, Users
                                    undertake not to invest funds they cannot afford to lose. Furthermore, Users acknowledge
                                    that past performance is no indication of future performance, which may go down as well as
                                    up.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>10.</small>
                                </li>
                                <li>
                                    On the other hand, Users are aware of the technical risks associated with the Services
                                    offered, including, the risks associated with a security error or failure, whether or not
                                    associated with blockchain, that may result in loss of data or access to stored digital assets.
                                </li>
                            </ul>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>11.</small>
                                </li>
                                <li>
                                    Finally, Customers are aware that the provision of Services does not constitute advice or a
                                    recommendation on the opportunity, terms or consequences of a Transaction.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>12.</small>
                                </li>
                                <li>
                                    <b>Risks associated with fraud and scams.</b> These risks include phishing
                                    / Lyber recommends that all its customers never divulge their login details or passwords, and
                                    that they update them regularly. Lyber advises all its customers never to divulge their login
                                    details and passwords, and to update them regularly.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>13.</small>
                                </li>
                                <li>
                                    <b>Territorial restrictions. </b> Access to the products and services presented on the Site may be
                                    subject to restrictions for certain persons or in certain countries. In any event, none of the
                                    products and/or services may be supplied by Lyber to any individual or legal entity if the law
                                    of their country of origin or of any other country does not authorize or prohibit it.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">3.</span>Navigation on Platform
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>14.</small>
                                </li>
                                <li>
                                    The Lyber website is available free of charge to all Users without the need to create a
                                    Customer Account.
                                </li>
                            </ul>


                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>15.</small>
                                </li>
                                <li>
                                    On the Website, Lyber provides all information necessary for Users to understand the
                                    operation of the Services.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>16.</small>
                                </li>
                                <li>
                                    The information and/or data provided on the website or on the mobile application for Users
                                    and Customers constitute indicators only and must in no way be interpreted as an incitement
                                    to take any decision whatsoever.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>17.</small>
                                </li>
                                <li>
                                    The Lyber platform can be navigated via mobile applications. For IOS mobiles, the
                                    application is available in the Apple Store. And for Android mobiles, the application is
                                    available in Google Play Store (coming soon).
                                </li>
                            </ul>

                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">4.</span>Accounts
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>18.</small>
                                </li>
                                <li>
                                    In order to benefit from the Services provided by Lyber, Users must open a Customer
                                    Account.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>19.</small>
                                </li>
                                <li>
                                    Unless specifically authorized, Users may not create more than one Customer Account.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>20.</small>
                                </li>
                                <li>
                                    Accounts are accessible 7 days a week, 24 hours a day, except in the event of maintenance
                                    or updates.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">4.1</span>Creating a Customer Account
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>21.</small>
                                </li>
                                <li>
                                    <b>Creation.</b> To use the Platform (as described below), the User is invited to create a personal
                                    and unique Customer Account by :
                                    <ul>
                                        <li>- Entering username and e-mail address ;  </li>
                                        <li>- Choosing a (strong) password ;</li>
                                        <li>- Compliance with anti-money laundering and combating the financing of terrorism
                                            (AML/CFT) requirements ; </li>
                                        <li>- Accepting these GTC and the privacy policy.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>22.</small>
                                </li>
                                <li>
                                    Lyber informs Users that the opening of an Account is subject to LCB-FT identification
                                    requirements, possibly through a Partner. Lyber may refuse to open an Account for any legal
                                    reason, without justification. This refusal will not give rise to any damages.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>23.</small>
                                </li>
                                <li>
                                    Users undertake to provide accurate and precise information regarding their marital status and
                                    contact details.
                                </li>
                            </ul>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>24.</small>
                                </li>
                                <li>
                                    <b>US-PERSON specificity. </b>In application of the Foreign Account Tax Compliance Act
                                    (FATCA), any US citizen residing in France and the European Union using this platform
                                    must inform Lyber.
                                </li>

                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>25.</small>
                                </li>
                                <li>
                                    The identity verification process lasts a maximum of 48 hours, subject to the correctness of
                                    the documents received. However, users may explore the Lyber application without
                                    benefiting from LYBER services. To benefit from LYBER's services, the user will see a
                                    popup asking him/her to electronically sign the Actif Numérique Service Provider contract.
                                </li>

                            </ul>

                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">4.2</span>Account security
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>26.</small>
                                </li>
                                <li>
                                    Users must not under any circumstances divulge their usernames and passwords, which are
                                    strictly personal and confidential. Users undertake to take all necessary measures to secure
                                    access to their Account login and password. To access services, Users are required to use
                                    two-factor authentication (2FA).
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>27.</small>
                                </li>
                                <li>
                                    Each User is responsible for the actions of any person using his or her Account, including
                                    without prior consent. Users undertake to report any fraudulent use of their Account, login or
                                    password to contact@lyber.com as soon as possible.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>28.</small>
                                </li>
                                <li>
                                    Each User is entirely responsible for the use of his Accounts and is, until proven otherwise,
                                    deemed to be the author of all instructions given on the customer account, and more
                                    generally of all events and modifications occurring on his account.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>29.</small>
                                </li>
                                <li>
                                    Each User expressly declares that he/she has the capacity and/or has received the
                                    authorizations required to use the Services provided by Lyber and guarantees Lyber against
                                    any liability resulting from a false declaration.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>30.</small>
                                </li>
                                <li>
                                    Lyber shall not be liable for any errors in the User's information, for the loss or theft of a
                                    password by the User, or for any resulting usurpation of the User's Account.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">4.3</span>Blocking, closing and freezing assets
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>31.</small>
                                </li>
                                <li>
                                    <b>Account blocking. </b>The temporary and immediate blocking of a Customer Account may be
                                    carried out by Lyber for any reason, at its sole discretion, and in particular:
                                    <ul>
                                        <li>- If the Customer has not complied with the provisions of these GCS ;</li>
                                        <li>- If the Customer has provided Lyber with inaccurate, obsolete or incomplete identification
                                            data ;</li>
                                        <li>- If the Customer loses his/her user name and/or password, or in the event of suspected
                                            fraudulent use (in particular identity theft) ;</li>
                                        <li>- If the Customer is suspected of fraud or is in any way in breach o f AML/CFT
                                            requirements ;</li>
                                        <li>- If Lyber receives a number of claims relating to unauthorized Transactions.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>32.</small>
                                </li>
                                <li>
                                    In such cases, Lyber reserves the right (i) to apply penalties and management fees to the
                                    Customer and/or (ii) to claim damages from the Customer.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>33.</small>
                                </li>

                                <li>
                                    Customers concerned will be informed of this decision by any means. This decision will not
                                    give rise to any claim for damages.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>34.</small>
                                </li>
                                <li>
                                    Reactivation of a blocked account will be at Lyber's discretion, on the basis of any additional
                                    information or documents that may be requested.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>35.</small>
                                </li>
                                <li>
                                    <b>Account closure. </b>The Customer may, at any time, request the definitive deletion of his
                                    personal Customer Account under the conditions provided on the Platform by writing to
                                    contact@lyber.com, which implies the deletion of all his data, including the Customer's
                                    personal data beyond the time limits mentioned in the Privacy Policy (section 3 relating to
                                    the retention of personal data). The Platform cannot be held responsible for any loss
                                    whatsoever.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>36.</small>
                                </li>
                                <li>
                                    Lyber reserves the right to close a Customer Account in the event of a breach of these GTC,
                                    without notice or compensation.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>37.</small>
                                </li>
                                <li>
                                    <b>Freezing of assets. </b>In application of the LCB-FT measures implemented by Lyber, Customer
                                    Account funds may be frozen if the Customer is registered on the French national register of
                                    asset freezing measures.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>38.</small>
                                </li>
                                <li>
                                    As soon as the asset freeze measure is repealed, cancelled or suspended by a competent
                                    authority or jurisdiction, or when it has expired, Lyber lifts all restrictions applied to the
                                    Transactions and assets of the Customer concerned and implements the appropriate
                                    vigilance measures.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">5.</span>Services
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>39.</small>
                                </li>
                                <li>
                                    <p>Subject to meeting the AML/CFT requirements mentioned above, Customers with validated
                                        Accounts may subscribe to the following Services offered by Lyber:</p>
                                    <ul>
                                        <li><b>- Buying Cryptocurrencies in Euros (5.1.);</b></li>
                                        <li><b>- Sale of Cryptocurrencies for Euros (5.2.);</b></li>
                                        <li><b>- Exchange of Cryptocurrencies for other Cryptocurrencies (5.3.)</b></li>
                                        <li><b>- Cryptocurrency storage (5.4.); and</b></li>
                                        <li><b>- Investment strategy (5.5).</b></li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>40.</small>
                                </li>
                                <li>
                                    All Services provided by Lyber fall within the scope of Article L.54-10-2 of the French
                                    Monetary and Financial Code. Lyber is registered with the Autorité des marchés financiers
                                    (AMF) as a PSAN, for all these activities, under number E2022-039.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>41.</small>
                                </li>
                                <li>
                                    Crypto-assets accepted and offered by LYBER are as follows:
                                </li>

                            </ul>

                            <Table hover striped className="roundedTable mb-5">
                                <thead>
                                    <tr>
                                        <th className="purple-light text-center" colSpan="3">Crypto Assets</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    <tr>
                                        <td className="light-black">Bitcoin (BTC)</td>
                                        <td className="light-black">Cardano (ADA)</td>
                                        <td className="light-black">Litecoin (LTC)</td>
                                    </tr>
                                    <tr>
                                        <td className="light-black">Ether (ETH)</td>
                                        <td className="light-black">Dogecoin (Doge)</td>
                                        <td className="light-black">Polygon (POL)</td>
                                    </tr>
                                    <tr>
                                        <td className="light-black">Binance Coin (BNB)</td>
                                        <td className="light-black">Ripple (XRP)</td>
                                        <td className="light-black">Pepe (PEPE)</td>
                                    </tr>
                                    <tr>
                                        <td className="light-black">ChainLink (LINK)</td>
                                        <td className="light-black">Polkadot (DOT)</td>
                                        <td className="light-black">Solana (SOL)</td>
                                    </tr>
                                    <tr>
                                        <td className="light-black">Fantom (FTM)</td>
                                        <td className="light-black">Shiba (SHIB)</td>
                                        <td className="light-black">Avalanche (AVAX)</td>
                                    </tr>
                                    <tr>
                                        <td className="light-black">Atom (ATOM)</td>
                                        <td className="light-black">Stellar (XLM)</td>
                                        <td className="light-black">MultiversX (EGLD)</td>
                                    </tr>
                                    <tr>
                                        <td className="light-black">Tether (USDT)</td>
                                        <td className="light-black">Circle (USDC)</td>
                                        <td className="light-black"></td>
                                    </tr>
                                </tbody>
                            </Table>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>42.</small>
                                </li>
                                <li>
                                    Lyber does not accept anonymous digital assets.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>43.</small>
                                </li>
                                <li>
                                    Minimum withdrawal: minimum withdrawal quantities vary from one digital asset to another
                                    due to price variations. For the purchase and sale of digital assets, the minimum quantity is
                                    €20.00. For the exchange of digital assets, the minimum quantity is €1.00.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>44.</small>
                                </li>
                                <li>
                                    The Company may withdraw a digital asset from the list of assets offered on the LYBER
                                    platform. In this case, the Company must inform the Customer in writing, at least 7 days
                                    before the withdrawal, of its intention to withdraw the asset from the list of assets offered by
                                    LYBER.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>45.</small>
                                </li>
                                <li>
                                    The Company cannot be held liable if the asset concerned is not withdrawn from its Portfolio
                                    within the allotted time.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>46.</small>
                                </li>
                                <li>
                                    Lyber has set up a "withdrawal verification window" between 8 a.m. and 7 p.m. during which
                                    LYBER staff verify transactions. The transactions concerned are deposits of digital assets,
                                    withdrawals of digital assets and withdrawals of fiat funds to a bank account. The User will
                                    always have the option of withdraw funds at any time. The User is informed that the Company may verify transactions
                                    at any time between 8 a.m. and 7 p.m. (GMT+2), seven days a week. Finally, the User is
                                    informed that two LYBER employees will be in charge of accepting withdrawal transactions
                                    from 10 a.m. to 7 p.m. (GMT+2) on Saturdays and Sundays.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.1</span>Buy Cryptocurrencies in Euros (fiat2crypto)
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>47.</small>
                                </li>
                                <li>
                                    <b>Description. </b>Any customer with a validated account under the conditions set forth in
                                    Section 4, may acquire cryptoassets via our Payment Service Provider through
                                    LYBER's mobile application against payment in euros by a means of payment
                                    accepted by the Payment Service Provider using the Apple Pay or Google Pay service
                                    depending on the nature of the mobile.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>48.</small>
                                </li>
                                <li>
                                    The Order must contain the following information:
                                    <ul>
                                        <li>- The amount in Euros that the Customer wishes to convert ;</li>
                                        <li>- The Cryptocurrency the Customer wants to buy ;</li>
                                        <li>- Customer's Internal Portfolio address.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>49.</small>
                                </li>
                                <li>
                                    In order to execute an order, the customer is given 10 to 30 seconds to validate the order
                                    and carry out the transaction. Once the order has been validated, it is transmitted to LYBER
                                    for execution.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>50.</small>
                                </li>
                                <li>
                                    Once acquired by Lyber on behalf of the Customer, through the Liquidity Provider, the amount
                                    of Cryptocurrencies concerned will be credited to the Customer's Internal Wallet.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>51.</small>
                                </li>
                                <li>
                                    Then, if the Customer so requests, the Cryptocurrencies will be transferred from his Internal
                                    Wallet to his External Wallet.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.2</span>Selling Cryptocurrencies for Euros (crypto2fiat)
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>52.</small>
                                </li>
                                <li>
                                    <b>Description. </b>Any customer with a validated account under the conditions provided in
                                    section 4, may sell his cryptoassets via LYBER's mobile application against payment
                                    in euros made by bank transfer by LYBER to the customer's personal account.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>53.</small>
                                </li>
                                <li>
                                    The Order must contain the following information:
                                    <ul>
                                        <li>
                                            - The category of CryptoCurrencies the Customer wishes to sell ;
                                        </li>
                                        <li>
                                            - The customer's bank account number (IBAN) with first and last name identical to that of
                                            Lyber's customer account.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>54.</small>
                                </li>
                                <li>
                                    In order to execute an order, the customer is given 10 to 30 seconds to validate the order
                                    and carry out the transaction. Once the order has been validated, it is transmitted to LYBER
                                    for execution.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>55.</small>
                                </li>
                                <li>
                                    Once exchanged by Lyber on behalf of the Customer, via the Cash Provider, the
                                    corresponding amount in Euros will be credited to the Customer's personal Account.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>56.</small>
                                </li>
                                <li>
                                    In the event of a subsequent Order from the Customer, the Euros will be transferred from his
                                    Payment Account to his personal bank account.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.3</span>Exchanging cryptocurrencies for other cryptocurrencies (crypto2crypto)
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>57.</small>
                                </li>
                                <li>
                                    <b>Description. </b>Any Customer with Accounts validated under the conditions set forth in Section
                                    4 may elect to exchange Cryptocurrencies for other Cryptocurrencies. To do so, the
                                    Customer must (i) supply Lyber's Wallet with Cryptocurrencies, either from his Internal
                                    Wallet or from his External Wallet and (ii) initiate the Order to Lyber specifying in which
                                    Cryptocurrencies he wishes the exchange to be made.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>58.</small>
                                </li>
                                <li>
                                    The Order must contain the following information:
                                    <ul>
                                        <li>- The Cryptocurrency the Customer wishes to exchange ;</li>
                                        <li>- The Cryptocurrency the Customer wishes to receive ;</li>
                                        <li>- Customer's Internal Portfolio address.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>59.</small>
                                </li>
                                <li>
                                    In order to execute an order, the customer is given 10 to 30 seconds to validate the order
                                    and carry out the transaction. Once the order has been validated, it is transmitted to LYBER
                                    for execution.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>60.</small>
                                </li>
                                <li>
                                    Once exchanged by Lyber on behalf of the Customer, via the Cash Provider, the
                                    corresponding Cryptocurrency amount will be credited to the Customer's Internal Wallet.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>61.</small>
                                </li>
                                <li>
                                    If the Customer so requests, Cryptocurrencies may be transferred from his Internal Wallet to
                                    his External Wallet.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.4</span>Cryptocurrency custody
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>62.</small>
                                </li>
                                <li>
                                    <b>Description. </b>Any Customer with Accounts validated under the conditions set forth in Section
                                    4, may elect to keep his Cryptocurrencies in his Internal Wallet, held by Lyber through the
                                    Custody Service Provider (the <b>Custody Service</b>").
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>63.</small>
                                </li>
                                <li>
                                    Customers are free to use their Internal Wallet to execute Transactions in connection with the
                                    use of the Services. They may at any time credit or debit their Internal Wallet from an
                                    External Wallet with their own Cryptocurrencies.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>64.</small>
                                </li>
                                <li>
                                    The balance of the Customer's Internal Portfolio may be consulted on the Platform by the
                                    Customer only.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>65.</small>
                                </li>
                                <li>
                                    <b>Performance. </b>Any Customer using the Custody Service expressly authorizes Lyber to
                                    manage the Cryptocurrencies held in its Internal Wallet for the duration of the Custody
                                    Service.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>66.</small>
                                </li>
                                <li>
                                    <b>Liability. </b>
                                    Lyber is fully responsible for any failure or default of the Custodian affecting the
                                    Customer's Internal Portfolio.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>67.</small>
                                </li>
                                <li>
                                    <b>Restitution. </b>Lyber will retain the Customer's Cryptocurrencies in its Internal Wallet until :
                                    <ul>
                                        <li>- The Customer requests the withdrawal of Cryptocurrencies from his External Wallet ; or</li>
                                        <li>- The Customer requests the conversion of Cryptocurrencies into Euros for transfer to his
                                            bank account ; or</li>
                                        <li>- The Customer Account is closed at the Customer's request ; or</li>
                                        <li>- These GTC may be terminated in accordance with the provisions of Section 13.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>68.</small>
                                </li>
                                <li>
                                    At the Customer's request, Lyber will return the retained Cryptocurrencies to the Customer as
                                    soon as possible, unless Lyber is confronted with an event beyond its control, for example if :
                                    <ul>
                                        <li>- Lyber has blocked or closed the Customer Account for a legitimate reason;</li>
                                        <li>- Lyber must carry out checks on the customer ;</li>
                                        <li>- Lyber has reason to believe that the request for return does not originate from the Customer ;</li>
                                        <li>- A competent authority refuses restitution ;</li>
                                        <li>- Lyber has been the victim of an attempted attack, an attack, a virus ;</li>
                                        <li>- The Platform is under maintenance ;</li>
                                        <li>- The Customer is unreachable for more than six (6) months.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.5</span>Investment strategies
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>69.</small>
                                </li>
                                <li>
                                    Lyber offers its users investment strategies.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>70.</small>
                                </li>
                                <li>
                                    These strategies do not constitute investment advice.
                                    Strategies enable users to place buy orders on a recurring, automatic basis. To do so, the
                                    user must have the funds to activate the strategies. The minimum investment is 10 USDT
                                    per asset. The higher the number of assets in a strategy, the higher the minimum
                                    investment.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>71.</small>
                                </li>
                                <li>
                                    2 strategies are available:
                                    <ul>
                                        <li>- Safe: the least risky strategy with 2 assets, low exposure to volatility</li>
                                        <li>- Intermediate: a riskier 4-asset strategy with higher volatility exposure</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>72.</small>
                                </li>
                                <li>
                                    What's more, each user can create their own investment strategy, with the digital assets they
                                    want and the recurrence of their choice.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>73.</small>
                                </li>
                                <li>
                                    Each strategy can be implemented in the following ways:
                                    <ul>
                                        <li>
                                            - One-time investment: i.e. the user activates the strategy once, and it will never be
                                            activated again unless the user decides to do so.
                                        </li>
                                        <li>
                                            - Recurring investment:
                                            <ul>
                                                <li>
                                                    - Daily: every day the user is debited to activate the strategy
                                                </li>
                                                <li>
                                                    - Weekly: every Thursday the user is charged to activate the strategy
                                                </li>
                                                <li>
                                                    - Monthly: every 21st of the month the user is debited to activate the strategy
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.6</span>Inactive account
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>74.</small>
                                </li>
                                <li>
                                    Lyber may apply a retention fee to any Internal Portfolio that has been inactive for more than
                                    one (1) year. We invite you to review our fee policy.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>75.</small>
                                </li>
                                <li>
                                    Generally speaking, after a period of 12 consecutive months, an account is considered
                                    inactive in two cases:
                                    <ul>
                                        <li>- If it has not recorded any transactions other than those initiated by LYBER (collection
                                            of fees and commissions, payment of interest) ;</li>
                                        <li>- If the holder has not contacted LYBER.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>76.</small>
                                </li>
                                <li>
                                    After 10 years of inactivity, LYBER informs the customer 6 months in advance by e-mail, and
                                    transfers the inactive account balance to the Caisse des Dépôts (CDC) if no response is
                                    received from the user within the allotted time.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>77.</small>
                                </li>
                                <li>
                                    The user can retrieve the assets of an account transferred to Caisse des Dépôts via its
                                    website: <a href="https://ciclade.caissedesdepots.fr/">https://ciclade.caissedesdepots.fr/</a>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>78.</small>
                                </li>
                                <li>
                                    The Ciclade website enables anyone and everyone to search for unclaimed or forgotten
                                    sums transferred by Lyber to Caisse des Dépôts.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">5.7</span>Orders
                                </strong>
                            </p>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>79.</small>
                                </li>
                                <li>
                                    Each Order must contain the relevant information mentioned above. Lyber cannot be held
                                    responsible if the details provided by the Customer are incorrect or not up to date.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>80.</small>
                                </li>
                                <li>
                                    Lyber reserves the right to cancel a Transaction if the credit/debit card transfer is rejected or
                                    cancelled by the card issuer.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>81.</small>
                                </li>
                                <li>
                                    Transactions are subject to the prior consent of the Customer concerned. In the absence of
                                    such consent, the Transaction or series of Transactions will be considered as unauthorized.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>82.</small>
                                </li>
                                <li>
                                    The Customer may cancel his Order as long a s it is not irrevocable (i.e. as long as the
                                    Customer has not confirmed his Order within the 10-30 second time limit). Consent to
                                    execute a series of Transactions may be withdrawn. In this case, any Transaction carried
                                    out after the withdrawal of consent will be considered as unauthorized.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>83.</small>
                                </li>
                                <li>
                                    Customers who wish to dispute a Transaction that has not been executed must contact Lyber
                                    at contact@lyber.com as soon as possible after becoming aware of the anomaly and no
                                    later than thirteen (13) months after the Transaction was recorded. Prior to this notification,
                                    in the event of an unauthorized Transaction resulting from the loss or theft of the payment
                                    instrument, the payer will bear the losses associated with the use of this instrument, up to a
                                    limit of fifty euros (€50).
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>84.</small>
                                </li>
                                <li>
                                    <b>Amount limits. </b>Transaction limits or Order blocks may be activated at any time by Lyber in
                                    the event of a risk of fraud. Any Transaction likely to exceed the applicable limits will be
                                    automatically rejected by Lyber.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>85.</small>
                                </li>
                                <li>
                                    <b>Execution times. </b>Payment transactions in euros, where both payment service providers are
                                    located in the European Economic Area, or involving a single conversion between euros, the
                                    account of the beneficiary of the payment transaction is credited no later than the end of the
                                    first working day following receipt of the order by LYBER. For digital asset transactions, the
                                    payee's account is credited no later than the end of the first business day following receipt of
                                    the order by LYBER.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>86.</small>
                                </li>
                                <li>
                                    <b>Availability. </b>Lyber will make the amount of the payment transaction for which the Customer
                                    is the beneficiary available to the Customer immediately after the Customer's account has
                                    been credited if the payment transaction does not require conversion.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>87.</small>
                                </li>
                                <li>
                                    <b>Value date. </b>Lyber assigns a value date to the payment transaction, which is the reference
                                    date it uses to calculate the interest applicable to funds debited or credited to the account.
                                    The value dates applied by Lyber are shown in the pricing brochure.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">6.</span>Product: the LYB token
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>88.</small>
                                </li>
                                <li>
                                    The fees applicable to Lyber's products and services are set out in the <a href="/pricing">politique tarifaire.</a>
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">7.</span>Non-application of the Consumer Code
                            </p>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>89.</small>
                                </li>
                                <li>
                                    Any Customer who is a natural person acting for purposes which do not fall within the scope
                                    of his commercial, industrial, craft or agricultural activity shall be identified as a consumer
                                    within the meaning of the preliminary article of the French Consumer Code.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>90.</small>
                                </li>
                                <li>
                                    The consumer's right of withdrawal under article L.221-18 of the French Consumer Code may
                                    not be exercised in the case of (i) the supply of digital content not provided on a physical
                                    medium, where performance has begun after the consumer has given his express prior
                                    consent and expressly waived his right of withdrawal; or (ii) the supply of goods or services
                                    whose price depends on fluctuations in the financial market beyond the control of the
                                    professional and which may occur during the withdrawal period (article L. 221-28 of the
                                    French Consumer Code).
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>91.</small>
                                </li>
                                <li>
                                    Consequently, by accepting these GCS, the Customer expressly waives his right of
                                    withdrawal, prior to the execution of the Services.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">8.</span>Responsibility of Parties
                            </p>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">8.1</span>Customer responsibility
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>92.</small>
                                </li>
                                <li>
                                    Customers warrant Lyber against any breach exceeding the foreseeable risk, against any
                                    fraudulent or bad faith use of the Services, and against any error in the amount sent to their
                                    Internal Wallet (customer account) as well as to their personal bank account in connection
                                    with a cryptoasset sale.
                                    <div className="text-rectangle">
                                        Customers agree to indemnify, defend and hold Lyber harmless against any claim brought
                                        against LYBER by a third party and arising from the use of the services, with the exception
                                        of a manifest breach by the Company of its obligations hereunder.
                                    </div>
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>93.</small>
                                </li>
                                <li>
                                    Customers further agree to indemnify and hold Lyber harmless from and against any and all
                                    liabilities, losses, damages, costs, claims and/or expenses, including but not limited to
                                    attorneys' fees, that Lyber may incur or be required to pay as a result of such claims.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">8.2</span>Lyber's liability
                                </strong>
                            </p>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>94.</small>
                                </li>
                                <li>
                                    Lyber undertakes to perform its obligations with the care normally expected of a professional
                                    in the same sector of activity and to respect current professional practices.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>95.</small>
                                </li>
                                <li>
                                    The Services are provided "as is" , to the exclusion of any warranty whatsoever. Lyber
                                    disclaims any liability or obligation to indemnify any person for any loss or damage caused
                                    by a Customer or any Third Party.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>96.</small>
                                </li>
                                <li>
                                    Lyber undertakes to use all necessary means to ensure the best possible provision of
                                    Services to its Customers. However, Lyber can only be held to an obligation of means.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>97.</small>
                                </li>
                                <li>
                                    Lyber does not guarantee continuous or uninterrupted access to the Services. Consequently,
                                    Lyber cannot be held liable for any delay and/or total or partial inaccessibility of the Services
                                    resulting from factors beyond its control.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>98.</small>
                                </li>
                                <li>
                                    Customers are informed that Lyber may occasionally interrupt access to all or part of the
                                    Services:
                                    <ul>
                                        <li>- To enable repairs, maintenance operations or improvements in functionality ;</li>
                                        <li>- In the event of suspicion of attempted piracy, embezzlement or any other risk of damage ;</li>
                                        <li>- On request or instruction from authorized persons or competent authorities.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>99.</small>
                                </li>
                                <li>
                                    Lyber shall in no event be liable for any damages resulting from such suspensions. Upon
                                    normal resumption of service, Lyber will use reasonable efforts to process outstanding
                                    Transactions as soon as possible.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>100.</small>
                                </li>
                                <li>
                                    In addition, Lyber cannot be held responsible in the event of:
                                    <ul>
                                        <li>- Refusal of a bank transfer ; </li>
                                        <li>- Unusual or illegal use of the Services ;</li>
                                        <li>- A deliberate or grossly negligent breach by a Customer of its obligations or any fault on its part ;</li>
                                        <li>- Breakdowns or malfunctions of the Services that are not the responsibility of Lyber ; </li>
                                        <li>- Cyber attacks ; </li>
                                        <li>- Indirect damages that may result from the Services provided by Lyber, such as an action
                                            brought by a Third Party against a Customer, commercial prejudice, loss of clientele,
                                            commercial disturbance, loss of profit, loss of data not attributable to Lyber, loss of brand
                                            image suffered by a Customer or a Third Party ;</li>
                                        <li>- Any case of force majeure as defined by article 1218 of the French Civil Code or any event
                                            beyond Lyber's control, as well as any measure taken or legislation adopted by French or
                                            foreign authorities.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>101.</small>
                                </li>
                                <li>
                                    Accordingly, Lyber may only be held liable if Customers prove that (i) Lyber acted in a
                                    wrongful and intentional manner and (ii) exclusively if such conduct caused certain, personal, direct and reasonably foreseeable damage, unless otherwise
                                    provided by law or regulation.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>102.</small>
                                </li>
                                <li>
                                    If Lyber is responsible for a non-executed Transaction, Lyber will promptly return the
                                    corresponding amount to the Customer and restore the debited Account to the position it
                                    would have been in had the mishandled Transaction not taken place.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>103.</small>
                                </li>
                                <li>
                                    In no event shall Lyber's liability exceed the amounts invoiced to Customers by the Platform in
                                    accordance with the GTCs during the twelve (12) months preceding the incident or series of
                                    incidents, unless otherwise provided by law or regulation.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">9.</span>Privacy
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>104.</small>
                                </li>
                                <li>
                                    Lyber undertakes to maintain the confidentiality of Customer Transactions, unless required to
                                    disclose such information by decree, law or court order, or in connection with any other
                                    request related to anti-money laundering and anti-terrorist financing obligations
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">10.</span>Data
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>105.</small>
                                </li>
                                <li>
                                    Certain information directly or indirectly identifying Users is processed by Lyber when using
                                    the Platform.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>106.</small>
                                </li>
                                <li>
                                    In order to inform Users in a transparent manner about the purposes and methods of
                                    processing their personal data, Lyber has established a Privacy Policy available on the
                                    Platform (<a href="https://www.lyber.com/privacy">https://www.lyber.com/privacy</a>)
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">11.</span>Proof
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>107.</small>
                                </li>
                                <li>
                                    Communications, including claims within the meaning of Section 18.1, made by electronic mail
                                    are modes of communication validly admitted as evidence by Users/Customers and Lyber.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>108.</small>
                                </li>
                                <li>
                                    In the absence of proof to the contrary, the data recorded in Lyber's computer system
                                    constitutes proof of all Transactions ordered by Customers.
                                </li>
                            </ul>

                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>109.</small>
                                </li>
                                <li>
                                    All information recorded in Lyber's computer databases relating to Orders, Transactions,
                                    deposits in Euros, deposits and/or withdrawals in Cryptocurrencies have, in the absence of
                                    proof to the contrary, the same probative force as a document written on paper, both as
                                    regards their content and as regards the date and time at which the information is given
                                    and/or received. This unalterable, secure and reliable tracking is stored and preserved in
                                    Lyber's computer systems.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>110.</small>
                                </li>
                                <li>
                                    Lyber documents reproducing this information, as well as copies or reproductions of
                                    documents, have the same evidential value as the original, until proven otherwise.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">12.</span>Modification
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>111.</small>
                                </li>
                                <li>
                                    Lyber reserves the right to modify or discontinue all or part of the Services and/or the Platform
                                    at any time.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>112.</small>
                                </li>
                                <li>
                                    Lyber reserves the right to modify, at any time, all or part of these GTC. Use of the Services
                                    by Customers constitutes acceptance of the modified GTC. The applicable version of the
                                    T&Cs is the latest version published on the date the Customer Account is created.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">13.</span>Cancellation
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>113.</small>
                                </li>
                                <li>
                                    In the event of a change in the applicable law or regulation and its interpretation by the
                                    relevant regulatory authority, affecting the ability of Lyber or its employees to perform the
                                    Services, these T&Cs shall automatically terminate.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>114.</small>
                                </li>
                                <li>
                                    Termination of these GCS entails closure of the Accounts. The closure of an Account may
                                    not give rise to any compensation, regardless of the prejudice caused by the closure of the
                                    Account. Unless expressly agreed otherwise by Lyber, the Customer whose Account has
                                    been closed by Lyber is not authorized to open another Account. Any Account opened in
                                    violation of this provision may be immediately closed by Lyber without notice.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>115.</small>
                                </li>
                                <li>
                                    Customers will no longer be able to initiate Orders from the effective date of termination.
                                    However, the Customer Account may be maintained for a period of thirteen (13) months to
                                    cover any subsequent disputes and claims. Transactions initiated prior to the effective date
                                    of termination will not be affected by the termination and will be executed in accordance with
                                    the terms of these GCS.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>116.</small>
                                </li>
                                <li>
                                    Any remaining funds will be transferred to the User's personal bank account.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">14.</span>Intellectual Property
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>117.</small>
                                </li>
                                <li>
                                    All intellectual property rights related to the Platform and the Services, including all
                                    trademarks, logos, text, software, databases, names, designs, themes, concepts, images
                                    and the arrangement thereof (collectively, the "Content") remain at all times the property of
                                    Lyber, its licensors and licensees and are protected by copyright, trademark and other
                                    applicable intellectual property rights.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>118.</small>
                                </li>
                                <li>
                                    No provision of these GTS may be interpreted as granting either Users or Customers any right
                                    whatsoever over the Content. It is therefore Users and Customers are prohibited from copying, reproducing, displaying, distributing or
                                    exploiting in any way, in whole or in part, the Contents, decompiling or attempting to
                                    decompile software, extracting or attempting to extract data from databases, interfering or
                                    attempting to interfere in any way with security measures implemented by Lyber, without the
                                    prior written consent of Lyber and/or its licensors, which will be granted at Lyber's sole
                                    discretion.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">15.</span>Mutual assistance
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>119.</small>
                                </li>
                                <li>
                                    If any administrative formalities are required for the performance of these GTC, Lyber and
                                    Users/Customers agree to assist each other in the completion of such formalities.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">16.</span>Hyperlinks
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>120.</small>
                                </li>
                                <li>
                                    The Services may contain links or content redirecting to third-party websites or resources.
                                </li>
                            </ul><ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>121.</small>
                                </li>
                                <li>
                                    These Third Party Services may be subject to different terms and conditions and privacy
                                    policies.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>122.</small>
                                </li>
                                <li>
                                    Lyber shall not be responsible for the availability, accuracy, content, products or services
                                    available from such links. The presence of such links does not constitute an endorsement of
                                    the Third Party Services by Lyber.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">17.</span>No waiver
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>123.</small>
                                </li>
                                <li>
                                    Lyber's failure at any time to require performance by the other Party of any of its obligations
                                    under these GTCs shall not affect Lyber's right to require performance thereafter.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>124.</small>
                                </li>
                                <li>
                                    The nullity of a clause does not affect the validity and obligation to respect the other clauses.
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">18.</span>Claims and disputes
                            </p>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">18.1</span>Claims
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>125.</small>
                                </li>
                                <li>
                                    In all circumstances, Users may contact Lyber to obtain any information they may require or to
                                    make any claim.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>126.</small>
                                </li>
                                <li>
                                    <b> Lyber can be contacted: </b>
                                    <ul>
                                        <li>- Use the contact form by <a href="/contact">clicking here</a>.</li>
                                        <li>- By email: contact@lyber.com.</li>
                                        <li>- By post: 15 rue des Halles, 75001 Paris 1, France ; </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>127.</small>
                                </li>
                                <li>
                                    In the event of a complaint, Lyber undertakes to:
                                    <ul>
                                        <li>- Acknowledge receipt of all customer complaints within ten (10) working days of receipt ;</li>
                                        <li>- Respond to Customers within fifteen (15) Business Days of receipt of the complaint.
                                            However, if additional time is required to respond, Lyber will inform the Customer of the
                                            reason for the delay. Lyber will keep the Customer informed of the progress of the
                                            complaint. In any event, the Customer will receive a definitive response no later than thirty-
                                            five (35) Business Days following Lyber's receipt of the claim.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">18.2</span>Mediation
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>128.</small>
                                </li>
                                <li>
                                    Any dispute or claim relating to (i) the information provided by Lyber under these T&c, (ii) an
                                    error in the performance or non-performance of the Services, or (iii) an error in the charging
                                    of commissions, taxes or fees by Lyber, must be notified to Lyber by the User/Customer as
                                    soon as possible after the User/Customer has knowledge or is deemed to have knowledge
                                    thereof.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>129.</small>
                                </li>
                                <li>
                                    In the event of a dispute arising from the application of these GCS, and failing amicable
                                    settlement, the consumer customer may refer the matter free of charge to an independent
                                    mediator, without prejudice to any other remedy. To do so, the consumer customer may
                                    contact the mediator by several means:
                                    <ul>
                                        <li>- Postal mail: Centre de Médiation et d’Arbitrage de Paris (CMAP), 39 Avenue Franklin D. Roosevelt 75008 PARIS</li>
                                        <li>- Email: cmap@cmap.fr</li>
                                        <li>- Phone: 01.44.95.11.40 du Lundi au Vendredi de 9h à 18H</li>
                                    </ul>
                                </li>
                            </ul>
                            <p className="peraghraph-heading">
                                <span className="me-lg-3 me-1">19.</span>Jurisdiction and applicable law
                            </p>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">19.1</span>Applicable law and language
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>130.</small>
                                </li>
                                <li>
                                    These GTC are governed exclusively by French law.
                                </li>
                            </ul>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>131.</small>
                                </li>
                                <li>
                                    In the event of any contradiction between the French and English versions of these GCS, or in
                                    the event of any difficulty of interpretation whatsoever, the French version shall prevail.
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    <span className="me-lg-3 me-1">19.2</span>Jurisdiction
                                </strong>
                            </p>
                            <ul className="d-flex justify-content-start align-item-center ps-0">
                                <li className="namesof-number ms-lg-1">
                                    {" "}
                                    <small>132.</small>
                                </li>
                                <li>
                                    Unless otherwise provided by law, all disputes or claims relating to or arising out of these GTC
                                    and any Transactions entered into pursuant thereto, concerning their validity, formation,
                                    interpretation, performance, termination and their consequences, will be submitted to the competent courts of the Paris Court of Appeal.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
