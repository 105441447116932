import { Table } from "react-bootstrap"

export const PricingPolicyFR = () => {
    return (
        <>
            <section className="privacy-policy pb-5 mb-3">
                <div className="container">
                    <h2 className="term-heading text-center mt-lg-4 mt-2 text-capitalize">
                        Politique tarifaire
                    </h2>
                    <br />
                    <br />
                    <br />

                    <p>
                        La présente politique porte sur les frais d’utilisation des principaux services liés à un compte de paiement d’une part et à un compte d’actif numérique d’autre part.
                    </p>
                    <p>
                        Tous nos prix sont indiqués en TTC.
                    </p>
                    <p>
                        Ces conditions tarifaires s’appliquent sur le site internet et sur l’application de LYBER.
                    </p>
                    <br />
                    <p>
                        La grille tarifaire exposée ci-dessous se présente sur 5 niveaux :
                    </p>

                    <ul>
                        <li>Niveau 1 : OUVERTURE ET FONCTIONNEMENT DE VOTRE COMPTE</li>
                        <li>Niveau 2 : SERVICE</li>
                        <li>Niveau 3 : PAIEMENT MONNAIE FIAT (EURO)</li>
                        <li>Niveau 4 : PAIEMENT CRYPTO ACTIF</li>
                        <li>Niveau 5 : SPECIFITE DES CRYPTO ACTIFS</li>
                    </ul>
                    <br />
                    <br />
                    <br />
                    <h3 className="text-center mt-lg-4 mt-2 text-capitalize">
                        GRILLE TARIFAIRE
                    </h3>
                    <br />
                    <Table hover striped className="roundedTable mb-5">
                        <thead>
                            <tr>
                                <th width="80%" className="purple-light">OUVERTURE ET FONCTIONNEMENT DE VOTRE COMPTE</th>
                                <th width="20%" className="purple-light">TARIF</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            <tr>
                                <td className="light-black">Ouverture d'un compte</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Clôture d'un compte</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Relevé de compte mensuel (dématérialisé)</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Relevé de frais et des commissions (dématérialisé)</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Modification des coordonnées et toutes informations relatives aux KYC<sup>1</sup></td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Tenue de compte</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Tenue de compte inactif au sens de l'article L.312-19 du CMF<sup>2</sup> et de la Loi Eckert</td>
                                <td className="light-purple">30 EUR / AN</td>
                            </tr>
                        </tbody>
                        <thead className="table-body">
                            <tr>
                                <th width="80%" className="purple-light">SERVICE</th>
                                <th width="20%" className="purple-light">TARIF</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            <tr>
                                <td className="light-black">Service des réclamations</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Service de médiation</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Commissions interventions<sup>3</sup></td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Abonnement à des services (stratégie d’investissement)<sup>4</sup></td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Consultation, gestion des comptes et messagerie sur le site internet</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Consultation, gestion des comptes et messagerie sur l'application</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                        </tbody>
                        <thead className="table-body">
                            <tr>
                                <th width="80%" className="purple-light">PAIEMENT MONNAIE FIAT (EURO)</th>
                                <th width="20%" className="purple-light">TARIF</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {/* <tr>
                                <td className="light-black">Virement bancaire SEPA entrant (dépôt)</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr> */}
                            <tr>
                                <td className="light-black">Paiement entrant (dépôt) via Apple Pay et Google Pay inférieur à 60 euros</td>
                                <td className="light-purple">3%</td>
                            </tr>
                            <tr>
                                <td className="light-black">Paiement entrant (dépôt) via Apple Pay et Google Pay supérieur ou égal à 60 euros</td>
                                <td className="light-purple">2%</td>
                            </tr>
                            <tr>
                                <td className="light-black">Virement sortant uniquement par virement bancaire SEPA (retrait)</td>
                                <td className="light-purple">0.66€</td>
                            </tr>
                            {/* <tr>
                                <td className="light-black">Conservation de monnaie fiat</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr> */}
                        </tbody>
                        <thead className="table-body">
                            <tr>
                                <th width="80%" className="purple-light">PAIEMENT CRYPTOACTIF (non inclus les frais de réseau répercutés aux clients)</th>
                                <th width="20%" className="purple-light">TARIF</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {/* <tr>
                                <td className="light-black">Virement entre ami d'un actif numérique vers un autre actif numérique (retrait - compte externe)</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Virement entre ami d'un actif numérique vers un autre actif numérique (retrait - compte interne)</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Ordre d'achat unitaire d'un actif numérique</td>
                                <td className="light-purple">1%</td>
                            </tr>
                            <tr>
                                <td className="light-black">Ordre de vente unitaire d'un actif numérique</td>
                                <td className="light-purple">1%</td>
                            </tr> */}
                            <tr>
                                <td className="light-black">Échange d’un actif numérique contre un autre actif numérique</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">Virement d'un actif numérique vers un compte interne (dépôt)<sup>6</sup></td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Virement d'un actif numérique vers un compte externe (retrait)<sup>7</sup></td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Virement d’un actif numérique depuis et vers un compte Lyber</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                            <tr>
                                <td className="light-black">Exécution d’une stratégie d’investissement par succession des ordres<sup>8</sup></td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">Conservation des actifs numériques</td>
                                <td className="light-purple">GRATUIT</td>
                            </tr>
                        </tbody>
                        <thead className="table-body">
                            <tr>
                                <th width="80%" className="purple-light">FRAIS BLOCKCHAIN</th>
                                <th width="20%" className="purple-light">TARIF</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            <tr>
                                <td className="light-black">Commission correspondant au remboursement des frais de blockchain avancés par Lyber pour tout achat et vente d'un cryptoactif contre un autre cryptoactif</td>
                                <td className="light-purple">Variation des frais blockchain en fonction du trafic sur la blockchain</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p className="footnote"><sup>1</sup> Know Your Customer, il s’agit d’un process d’identification et de vérification des utilisateurs de LYBER.</p>
                    <p className="footnote"><sup>2</sup> Code Monétaire et Financier</p>
                    <p className="footnote"><sup>3</sup> Il s’agit de tous types d’interventions incombant à LYBER pour investiguer et générer des frais au travers de ses fournisseurs. A titre d’exemple, il pourrait s’agir des réclamations payantes en raison d’une erreur provenant du client et LYBER devra intervenir pour investiguer.</p>
                    <p className="footnote"><sup>4</sup> Description de l’offre d’abonnement stratégie d’investissement : l’utilisateur a la possibilité de donner des instructions stratégiques permettant à LYBER d’exécuter de manière périodique les ordres. Seul ce service est gratuit, l’exécution des ordres reste payante. Ceci est valable uniquement pour une exécution d’ordre d’achat. </p>
                    <p className="footnote"><sup>5</sup> Le montant minimum pour tout dépôt par Apple Pay ou Google Pay est de 20 EUR.</p>
                    <p className="footnote"><sup>6</sup> Un compte interne se définit par un compte ouvert chez Lyber</p>
                    <p className="footnote"><sup>7</sup> Les dépôts et les retraits de cryptoactif sont gratuits, Lyber ne prélève pas de frais. Cependant des frais de réseau peuvent être appliqués indépendamment de la volonté de Lyber.</p>
                    <p className="footnote"><sup>8</sup> Une succession des ordres se définit par un ensemble des ordres placés par Lyber sur le marché afin d’exécuter au mieux la stratégie choisie par l’utilisateur. </p>
                    <br />
                    <br />
                    <br />
                    <h3 className="text-center mt-lg-4 mt-2 text-capitalize">
                        GRILLE TARIFAIRE SPECIFIQUE DES ACTIFS NUMERIQUES
                    </h3>
                    <br />
                    <Table hover striped className="roundedTable mb-5">
                        <thead>
                            <tr>
                                <th width="50%" className="purple-light">ACTIFS NUMERIQUES</th>
                                <th width="50%" className="purple-light">COMMISSIONS</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            <tr>
                                <td className="light-black">BITCOIN (BTC)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">ETHER (ETH)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">BINANCE COIN (BNB)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">CHAINLINK (LINK)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">FANTOM (FTM)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">ATOM (ATOM)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">MULTIVERSX (EGLD)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">CARDANO (ADA)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">DOGECOIN (DOGE)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">PEPE (PEPE)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">TETHER (USDT)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">RIPPLE (XRP)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">POLKADOT (DOT)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">SHIBA (SHIB)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">STELLAR (XLM)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">LITECOIN (LTC)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">POLYGON (POL)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">SOLANA (SOL)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">AVALANCHE (AVAX)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                            <tr>
                                <td className="light-black">CIRCLE (USDC)</td>
                                <td className="light-purple">0.5%</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p>Toute modification de la grille tarifaire et des commissions spécifiques liées aux actifs numériques sera portée à la connaissance du client dans la mesure du possible 30 jours avant l’entrée en vigueur des nouveaux tarifs.</p>
                </div>
            </section>
        </>
    );
};
